import React from "react";
import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { StaticImage } from "gatsby-plugin-image";
import { PageProps } from "gatsby";

import Testimonials from "../../components/thePit/Testimonials";
import Map from "../../components/thePit/Map";
import Boss from "../../components/thePit/Boss";
import PitMap from "../../components/thePit/PitMap";
import Slider from "../../components/thePit/Slider";
import Events from "../../components/thePit/Events";
import ThePit from "../../components/thePit/ThePit";

const pit = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="The Pit" location={location} type="website" />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          zIndex: -1,
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Slider />

      <Events />
      
      <StaticImage
        src="../../images/brandLines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default pit;
