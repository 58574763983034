import React, { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink } from "gatsby";

import Arrow from "../../images/arrow-right.inline.svg";
import sideBrand2 from "../../images/pitLine2.svg";
import Location2 from "../../images/location2.inline.svg";
import Calendar from "../../images/calendar2.inline.svg";

import play from "../../images/pit/play.svg";
import CloseIcon from "../../images/x.inline.svg";
import ethdenver from "../../images/pit/ethdenver.svg";
import zkweek from "../../images/pit/zkweekIcon.svg";
import patp from "../../images/pit/pat.svg";
import xhack from "../../images/pit/xhack.svg";
import zk from "../../images/pit/zkweek.svg";
import google from "../../images/pit/google.svg";
import ethcc from "../../images/pit/ethcc.svg";
import web3gw from "../../images/pit/web3gw.svg";
import web3gwbig from "../../images/pit/web3gwbig.png";

export const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: "20px",
};
export const videoStyle = {
  backgroundColor: "rgba(0,0,0,1)",
  width: "100%",
  maxWidth: 1000,
  maxheight: "90%",
  position: "relative",
  boxShadow: "0 0 30px 0 rgba(0,0,0,2)",
};

const eventImage = {
  background: "#091A3D",
  border: "1px solid #4B608D",
  display: "flex",
  height: 83,
  width: 180,
  justifyContent: "center",
  alignItems: "center",
};
const eventInfo = {
  width: { xs: "100%", md: "55%" },
  px: { xs: 2, md: 4 },
  pt: 4.8,
  pb: { xs: 3, md: 4 },
  position: "relative",
};

const eventButton = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 2,
  width: { xs: "40px", md: "70px" },
};

const eventBox = {
  position: "absolute",
  left: "-20px",
  top: "0",
  transform: "translateY(-50%)",
  zIndex: 2,
  "&:before": {
    content: "''",
    background: "#091A3D",
    border: " 1px solid #4B608D",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "10px",
    top: "-10px",
    zIndex: -1,
  },
};

const eventImageContainer = {
  minHeight: 300,
  position: "relative",
  width: { xs: "100%", md: "45%" },
  borderRight: "1px solid #4B608D",
  cursor: "pointer",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(9, 26, 61, 0.55), rgba(9, 26, 61, 0.55))",
    transition: ".3s ease-in-out",
  },
  "&:hover:after": {
    opacity: 0.5,
  },
};

const Events = () => {
  const [open, setOpen] = useState(false);

  const [videoID, setVideoID] = useState({
    video: "",
    platform: "",
  });
  const handleOpen = (video: string, platform: string) => {
    setVideoID({
      video: video,
      platform: platform,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideoID({
      video: "",
      platform: "",
    });
  };
  return (
    <>
      <Box
        component="div"
        sx={{
          position: "relative",
          pt: 20,
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 100,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 98,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{
          px: 2,
          textAlign: "center",
          mb: 8,
        }}
      >
        <Typography variant="h2" sx={{ mb: 1.6 }}>
          Events in{" "}
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            the Pit
          </Box>
        </Typography>
        <Typography>Where communities come together to build</Typography>
      </Box>

      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          mx: "auto",
          mb: { xs: 8, md: 12 },
        }}
      >
        <Typography variant="h3" sx={{ mb: 4, pl: { xs: 0, md: 6 } }}>
          Past { }
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            Events_
          </Box>
        </Typography>
        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
            onClick={() => {
              handleOpen("7_mT9BLVt8E", "youtube");
            }}
          >
            <Box component="div" sx={eventBox}>
              <Box component="div" sx={eventImage}>
                {" "}
                <Box
                  component="img"
                  src={web3gw}
                  alt="Building with Google Cloud and the Pit"
                />
              </Box>
            </Box>
            <StaticImage
              src="../../images/pit/web3gwbig.png"
              alt="Building with Google Cloud and the Pit"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Location2 />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Singapore
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    June{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      12th - 17th
                    </Box>
                    , 2023
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              Building with Google Cloud and the Pit
            </Typography>
            <Typography>
              For five days in Singapore, Google Cloud and the Pit brought together builders,
               innovators, and visionaries at the intersection of gaming and Web3. Participants
                explored new worlds in on-chain gaming, networked, and pitched their own ideas.
            </Typography>
            <Button
              component={RouterLink}
              to="/thepit/google-the-pit-web3-gaming-week"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2.4 }}
            >
              .Learn More
            </Button>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
            onClick={() => {
              handleOpen("7_mT9BLVt8E", "youtube");
            }}
          >
            <Box component="div" sx={eventBox}>
              <Box component="div" sx={eventImage}>
                {" "}
                <Box
                  component="img"
                  src={zkweek}
                  alt="Unlocking the Depths of Zero-Knowledge"
                />
              </Box>
            </Box>
            <Box component="img" src={play} sx={eventButton} />
            <StaticImage
              src="../../images/pit/zkweekVideo.png"
              alt="Unlocking the Depths of Zero-Knowledge"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Location2 />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Chicago
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    May{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      15th - 20th
                    </Box>
                    , 2023
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              Unlocking the Depths of Zero-Knowledge
            </Typography>
            <Typography>
              The Pit delved deep into every facet of the zero-knowledge
              universe during zkWeek. This immersive event unfolded at our
              headquarters in Chicago and showcased the brightest
              cryptographers, researchers, and experts in the field.
            </Typography>
            <Button
              component={RouterLink}
              to="/thepit/zkweek"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2.4 }}
            >
              .zkWeek
            </Button>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
            onClick={() => {
              handleOpen("D5njM0gZ-GE", "youtube");
            }}
          >
            <Box component="div" sx={eventBox}>
              <Box component="div" sx={eventImage}>
                {" "}
                <Box component="img" src={ethdenver} alt="" />
              </Box>
            </Box>
            <Box component="img" src={play} sx={eventButton} />
            <StaticImage
              src="../../images/pit/denver.png"
              alt="EthDenver"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Location2 />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Denver
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Feb{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      24th{" "}
                    </Box>
                    {" - "}
                    Mar{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      4th
                    </Box>
                    , 2023
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              The Pit{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                EthDenver
              </Box>
            </Typography>
            <Typography>
              From Feb. 24 through Mar. 4, the Pit and Encode Club teamed up to
              create the developer's home away from home during ETHDenver. Our
              pop-up space offered a focused place to work and chill place to
              hang out. A huge thanks to everyone who came out, shared their
              insights, and built in the Pit.
            </Typography>
            <Button
              component={OutboundLink}
              href="https://www.encode.club/the-pit"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2.4 }}
              target="_blank"
              rel="noreferrer"
            >
              .EthDenver
            </Button>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
            onClick={() => {
              handleOpen("1anzxJcWP9M", "youtube");
            }}
          >
            <Box component="div" sx={eventBox}>
              <Box component="div" sx={eventImage}>
                <Box component="img" src={patp} alt="" />
              </Box>
            </Box>
            <Box component="img" src={play} sx={eventButton} />
            <StaticImage
              src="../../images/pit/polygon.png"
              alt="zkweek"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Location2 />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Singapore
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Jan{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      30th{" "}
                    </Box>
                    {" - "}
                    Feb{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      10th
                    </Box>
                    , 2023
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                Polygon{" "}
              </Box>
              at the Pit
            </Typography>
            <Typography>
              Polygon at the Pit 2023 saw 150+ developers, speakers and industry
              leaders come together in Singapore for two consecutive weeks of
              discussions and workshops surrounding the future of Web3.
            </Typography>
            <Button
              component={RouterLink}
              to="/polygonatthepit"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2.4 }}
            >
              .Polygon at the Pit
            </Button>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
            onClick={() => {
              handleOpen("773373430", "vimeo");
            }}
          >
            <Box component="div" sx={eventBox}>
              <Box component="div" sx={eventImage}>
                {" "}
                <Box component="img" src={xhack} alt="" />
              </Box>
            </Box>
            <Box component="img" src={play} sx={eventButton} />
            <StaticImage
              src="../../images/pit/video.png"
              alt="xHack"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Location2 />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Chicago
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    Sep{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      26th{" "}
                    </Box>
                    {" - "}
                    Oct{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      21st
                    </Box>
                    , 2022
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                xHack{" "}
              </Box>
              at the Pit
            </Typography>
            <Typography>
              xHack may be over, but its impact is still being felt by the over
              300 developers who came through the doors. Watch the video, be
              inspired, and get yourself to the Pit.
            </Typography>
            <Button
              component={RouterLink}
              to="/xhack"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2.4 }}
            >
              .xHack
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose} sx={modalStyle}>
        <Box component="div" sx={videoStyle}>
          <Button
            onClick={handleClose}
            sx={{
              width: 30,
              height: 30,
              border: "1px solid #fff",
              borderRadius: "50%",
              position: "absolute",
              right: -15,
              top: -15,
              display: "block",
              zIndex: 2,
              backgroundColor: "#fff",
              svg: {
                fill: "#000",
                transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
              },
              "&:hover": {
                backgroundColor: "#fff",
                svg: {
                  transform: "scale(1.25)",
                  fill: "red",
                },
              },
            }}
          >
            <CloseIcon />
          </Button>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            {videoID.platform === "vimeo" ? (
              <iframe
                src={`https://player.vimeo.com/video/${videoID.video}?h=773c1a597b&autoplay=1&loop=1&color=ffffff&title=0&byline=0&portrait=0`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <iframe
                src={`https://www.youtube.com/embed/${videoID.video}?autoplay=1`}
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Events;
