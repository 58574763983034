import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { StaticImage } from "gatsby-plugin-image";
import sideBrand2 from "../../images/pitLine2.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../../styles/pagination.css";
import logo from "../../images/pit/pitlogo.svg";
const Slider = () => {
  return (
    <>
      <Box
        component="div"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: 50,
            top: -120,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      >
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 1082,
            mx: "auto",
            mt: { xs: 12, md: 20, lg: 40 },
            mb: { xs: 8, md: 26 },
          }}
        >
          <Box
            component="div"
            sx={{
              width: { xs: "100%", md: "50%" },
              maxWidth: { md: 536 },
              pr: { md: 4 },
              textAlign: { xs: "center", sm: "initial" },
            }}
          >
            <Typography variant="h1" sx={{ mb: 3.2 }}>
              The place{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                to build.
              </Box>
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Designed to foster connections and inspire, the Pit brings together passionate developers, artists, founders, and everyone in between.
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              Welcome to the Pit.
            </Typography>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            position: { xs: "relative", md: "absolute" },
            left: { md: "50%" },
            top: { md: "50%" },
            transform: { md: "translateY(-50%)" },
            width: { xs: "100%", md: "50%" },
            maxWidth: 586,
            mb: { xs: 8, md: 0 },
            mx: { xs: "auto", md: 0 },
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="the Pit"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              transform: {
                xs: "translate(20px, -50%)",
                lg: "translate(50%, -50%)",
              },
              width: { xs: 100, lg: "auto" },
              zIndex: 3,
            }}
          />
          <Swiper loop navigation modules={[Navigation]}>
            <SwiperSlide>
              <StaticImage
                src="../../images/pit/1.jpg"
                layout="fullWidth"
                placeholder="blurred"
                alt="Pit"
                style={{ border: "1px solid #4B608D" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../../images/pit/2.jpg"
                layout="fullWidth"
                placeholder="blurred"
                alt="Pit"
                style={{ border: "1px solid #4B608D" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../../images/pit/3.jpg"
                layout="fullWidth"
                placeholder="blurred"
                alt="Pit"
                style={{ border: "1px solid #4B608D" }}
              />
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default Slider;
